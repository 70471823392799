import ComponentAppBar from "./Components/ComponentAppBar/ComponentAppBar";
import SwipeableTextMobileStepper from "./Components/SwipeableTextMobileStepper";
import Container from "@mui/material/Container";
import QuiltedImageList from "./Components/QuiltedImageList";
import CustomTabPanel from "./Components/CustomTabPanel"
import ActionAreaCardImage from "./Components/ActionAreaCardImage";
import ActionAreaCardVideo from "./Components/ActionAreaCardVideo";
import styles from './App.module.sass'
import video from "./video/PSUTIOUTDOOR.mp4";
import React from "react";
import Button from "@mui/material/Button";
import TelegramIcon from '@mui/icons-material/Telegram';
import googles from "./img/googles.jpg";
import classroom from "./img/classroom..JPG";
import CustomizedDialogsForEquipment from './Components/CustomizedDialogsForEquipment'
import {createTheme, ThemeProvider} from "@mui/material";

import videoIvan from "./video/IvanFPV.mp4";

const listCardImage = [
    {
        header: 'Googles',
        description: 'Модифицированные FPV очки для полета на анлоговых и цифровых передатчиках',
        image: googles
    },
    {
        header: 'FPV Комната',
        description: 'Комната снабжена всем необходимым для отдыха и работы любому FPVшнику',
        image: classroom
    },
]

const listCardVideo = [
    {
        header: 'Рекордсмен',
        description: 'Уже через несколько месяцев Слезко Иван поставил в симуляторе молниеносный рекорд, который еще ни кому не получилось побить',
        video: videoIvan
    },
]
const theme = createTheme();

theme.typography.h3 = {
    fontSize: '1.2rem',
    '@media (min-width:600px)': {
        fontSize: '2.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '4.4rem',
    },
};
function App() {
  return (
    <>
        <ThemeProvider theme={theme}>
      <ComponentAppBar/>
        <Container maxWidth="xl" style={{padding: 0}}>
            <div className={styles.AppHeader}>
                <video autoPlay loop muted className={styles.AppHeaderVideo}>
                    <source src={video} type="video/mp4"></source>
                </video>
                <div className={styles.AppHeaderDiv}>
                    <h1 className={styles.AppHeaderH1}>Street X</h1>

                    <Button onclick="location.href='https://t.me/+yXPY1KAGYX1iNGQy'" onClick={(e) => {
                        e.preventDefault();
                        window.location.href='https://t.me/+yXPY1KAGYX1iNGQy';
                    }} variant="contained" endIcon={<TelegramIcon/>}>
                        Telegram
                    </Button>
                </div>
            </div>

            <div className={styles.flexPanel}>
                <SwipeableTextMobileStepper/>
                <CustomTabPanel/>
            </div>
            <div></div>
            <div className={styles.flexPanel} style={{flexDirection: 'row-reverse'}}>
                <QuiltedImageList/>
                <div className={styles.DivActionAreaCard}>
                    {listCardImage.map((item, key)=>{
                        return (
                            <ActionAreaCardImage props={item} key={key}/>
                        )
                    })}
                    {listCardVideo.map((item, key)=>{
                        return (
                            <ActionAreaCardVideo props={item} key={key}/>
                        )
                    })}
                </div>
            </div>

        </Container>
            </ThemeProvider>

        <header>

        </header>
    </>
  );
}

export default App;
