import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styles from '../App.module.sass'
import CustomizedDialogsForEquipment from './CustomizedDialogsForEquipment'

function CustomTabPanel(props) {


    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box className={styles.BasicTabs}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="О Street X" {...a11yProps(0)} />
                    <Tab label="Наше снаряжение" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                Street-X - кружок основан в стенах Поволжского государственного университета телекоммуникаций и
                информатики для обучения техникам управления БПЛА в различных режимах пилотирования и конструирования
                удаленных устройств и систем для примененеия в различных сферах.
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                Наши пилоты используют 2,5 дюймовые дроны
                BETAFPV Pavo25 с аналоговой и цифровой видеопередачей
                работающие на системе Avatar от Walksnail. Аппаратура управления
                BETAFPV LiteRadio 3 Pro
                со встроенным TX модулем работующем на протоколе ELRS.
                Для трансляции видео изображения пилоту используются FPV видео-очки Skyzone SKY02O
                с установленным модулем приема цифрового изображения Walksnail Avatar VRX для аналоговых видеоочков.
            </CustomTabPanel>
        </Box>
    );
}