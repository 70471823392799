import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import styles from '../App.module.sass'
import imagework from '../img/imagework.jpg'
import svg from '../svg/drone-1-svgrepo-com.svg'
import mg1 from '../img/fotos/1.jpg'
import mg2 from '../img/fotos/2.jpg'
import mg3 from '../img/fotos/3.jpg'
import mg4 from '../img/fotos/4.jpg'
import mg5 from '../img/fotos/5.jpg'
import mg6 from '../img/fotos/6.jpg'
import mg7 from '../img/fotos/7.jpg'
import mg8 from '../img/fotos/8.jpg'
import mg9 from '../img/fotos/9.jpg'
import mg10 from '../img/fotos/10.jpg'
import mg11 from '../img/fotos/11.jpg'

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${
            size * rows
        }&fit=crop&auto=format&dpr=2 2x`,
    };
}

export default function QuiltedImageList() {
    return (
        <ImageList
            className={styles.QuiltedImageList}
            variant="quilted"
            cols={4}
            rowHeight={'100%'}
        >
            {itemData.map((item) => (
                <ImageListItem key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                    <img
                        {...srcset(item.img, 221, item.rows, item.cols)}
                        alt={item.title}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    );
}

const itemData = [
    {
        img: imagework,
        title: 'За работой',
        rows: 2,
        cols: 2,
    },
    {
        img: mg1,
        title: 'Burger',
    },
    {
        img: mg2,
        title: 'Camera',
    },
    {
        img: mg3,
        title: 'Coffee',
        cols: 2,
    },
    {
        img: mg4,
        title: 'Hats',
        cols: 2,
    },
    {
        img: mg5,
        title: 'Honey',
        author: '@arwinneil',
        rows: 2,
        cols: 2,
    },
    {
        img: mg6,
        title: 'Basketball',
    },
    {
        img: mg7,
        title: 'Fern',
    },
    {
        img: mg8,
        title: 'Mushrooms',
        rows: 2,
        cols: 2,
    },
    {
        img: mg9,
        title: 'Tomato basil',
    },
    {
        img: mg10,
        title: 'Sea star',
    },
    {
        img: mg11,
        title: 'Bike',
        cols: 2,
    },
];