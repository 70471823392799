import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
// import video from "../video/fail.mp4";
import video from "../video/IvanFPV.mp4";
export default function ActionAreaCardVideo(props) {
    return (
        <Card sx={{ width: 345, maxHeight: 300, marginTop: 0 }}>
            <CardActionArea>
                <CardMedia
                    component="video"
                    style={{objectFit: 'cover'}}
                    height="140"
                    src={props.props.video}
                    alt="видео"
                    autoPlay
                    loop
                    muted
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.props.header}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{minHeight:100}}>
                        {props.props.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}