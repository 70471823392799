import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import img1 from "../img/contemplative-reptile.jpg"
import googles from '../img/googles.jpg'
import classroom from '../img/classroom..JPG'

const listEquipment =[
    {
        name: 'BETAFPV Pavo25',
        features: [
            'Приёмник: Steadyview 5,8ГГц 48 каналов',
            'Соотношение сторон: 16:9 и 4:3 (переключаемое)',
            'Дисплей: OLED 640 х 400',
            'Угол обзора: до 29°',
            'Встроенный вентилятор для отвода тепла и устранения запотевания',
            'Диапазон межзрачкового расстояния: от 59 до 69 мм',
            'Функция сканирование каналов',
            'Языки: китайский, английский',
            'Вход HDMI',
            'Поддерживает питание от 2S до 6S',
            'Встроенный Headtracker (контроллер движений головы)',
            'DVR: MJPEG, 30 кадров в секунду, AVI, поддержка SD-карт до 64 ГБ',
            'Поставляется с всенаправленной (Omni) и патч (Patch) антеннами',
            'Размеры: 167 х 70 х 79 мм',
            'Вес: 210 г',
            'Цвет: чёрный',
        ]
    },
]
export default function ActionAreaCardImage(props) {
    return (
        <Card sx={{ width: 345, maxHeight: 300, marginBottom: 5 }}>
            <CardActionArea  >
                <CardMedia
                    component="img"
                    height="140"
                    src={props.props.image}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        {props.props.header}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{minHeight:100}}>
                        {props.props.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>

    );
}